<ng-container *ngIf="projectName === testBookProject && showFooter">
  <footer class="footer" *ngIf="showFooter" [ngClass]="{'footer--spacing': showContinueinApp}">
    <seo-sub-footer></seo-sub-footer>
    <div class="wrapper">
      <div class="footer-wrapper">
        <div class="footer-column">
          <svg width="178" height="40" class="footer-logo">
            <use xlink:href="#testbook-logo-white"></use>
          </svg>
          <div class="brand-text">Testbook Edu Solutions Pvt. Ltd. </div>
          <div class="address">
            2nd Floor, Plot No. 4, Minarch Tower, Sector-44, Gurgaon,<br> Haryana, India, 122003
          </div>
          <a href="mailto:support@testbook.com">support@testbook.com</a>
          <div>Toll Free:<a href="tel:18002030577">1800 203 0577</a></div>
          <div>Office Hours: 10 AM to 7 PM (all 7 days)</div>
        </div>

        <div class="footer-column">
          <div class="h4">Company</div>
          <div class="category-item">
            <a target="_self" href="/about-us">About us</a>
            <a target="_self" href="/careers">Careers <div class="badge-dark-fill"> We are hiring</div></a>
            <a target="_blank" href="/teach">Teach Online on Testbook</a>
            <!--                    <a target="_self" href="/partners">Partners</a>-->
            <a target="_self" href="/news">Media</a>
            <a target="_self" href="/sitemap">Sitemap</a>
          </div>
        </div>
        <div class="footer-column">
          <div class="h4">Products</div>
          <div class="category-item">
            <a target="_self" href="/online-test-series">Test Series</a>
            <a routerLink="/free-live-tests-and-quizzes">Live Tests and Quizzes</a>
            <a routerLink="/pass">Testbook Pass</a>
            <a target="_self" href="/free-live-classes">Online Videos</a>
            <a target="_self" href="/practice-questions">Practice</a>
            <a routerLink="/free-live-classes">Live Classes</a>
            <a target="_self" href="/blog/">Blog</a>
            <a target="_self" href="/referrals">Refer & Earn</a>
            <a target="_self" href="https://testbook.com/promos/mock-test-book.html">Books</a>
            <a target="_self" href="/government-exam-calendar" class=""> Exam Calendar</a>
            <a target="_self" href="/current-affairs/current-affairs-quiz" class=""> GK & CA</a>
            <a target="_blank" href="/teachers-training-program"> Teacher Training Program</a>
            <a target="_blank" href="/doubts">Doubts</a>
            <a target="_self" href="https://testbook.com/promos/skillacademy-hire-from-us.html">Hire from
              SkillAcademy</a>
          </div>
        </div>

        <div class="footer-column">
          <div class="h4">Our App</div>
          <a class="mb-4" *ngIf="!isMobile || isIosDevice" href="https://link.testbook.com/iosAppStore">
            <img loading="lazy" alt="app-store" src='../../angular/assets/img/template-img/appstore.svg'>
          </a>
          <a class="mb-4" *ngIf="!isMobile || !isIosDevice" href="https://testbook.app.link/4D2CxmX4r6">
            <img alt="play-store" src="../../angular/assets/img/template-img/playstore.svg">
          </a>
          <div class="h4">Follow us on</div>
          <ul class="category-item">
            <li class="fl-social">
              <a href="//www.facebook.com/testbookdotcom" target="_blank" rel="noopener"
                class="tb-fb d-inline-block align-top">
                <svg width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#facebook"></use>
                </svg>
              </a>
              <a href="//twitter.com/testbookdotcom" target="_blank" rel="noopener"
                class="tb-tw d-inline-block align-top">
                <svg class="d-inline-block align-top" width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#twitter"></use>
                </svg>
              </a>
              <a href="//www.linkedin.com/company/testbook-com" target="_blank" rel="noopener"
                class="tb-li d-inline-block align-top">
                <svg class="d-inline-block align-top" width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#linkdin"></use>
                </svg>
              </a>
              <a href="//www.instagram.com/testbookdotcom/" target="_blank" rel="noopener"
                class="tb-ig d-inline-block align-top">
                <svg class="d-inline-block align-top" width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#instagram"></use>
                </svg>
              </a>
              <a href="//www.youtube.com/c/TestbookSuperCoaching" target="_blank" rel="noopener"
                class="tb-yt d-inline-block align-top">
                <svg class="d-inline-block align-top" width="20" height="20" fill="#86a1ae">
                  <use xlink:href="#youtube"></use>
                </svg>
              </a>
              <a href="//www.quora.com/profile/Testbook-6" target="_blank" rel="noopener">
                <svg class="d-inline-block align-top" width="15" height="18">
                  <use xlink:href="#quora"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="divider"></div>
      <div class="copyright-wrapper">
        <div class="copyright">Copyright © 2014-2024 Testbook Edu Solutions Pvt. Ltd.: All rights reserved</div>
        <a target="_self" href="/acceptable-use-policy">User Policy</a>
        <a target="_self" href="/terms-of-service">Terms</a>
        <a target="_self" href="/privacy-policy">Privacy</a>
      </div>
    </div>
  </footer>
</ng-container>
<ng-container *ngIf="projectName === plutusProject && showFooter">
  <footer class="footer plutus-footer">
    <div class="wrapper">
      <div class="footer-wrapper">

        <div>
          <ul class="category-item">
            <li><a prevent-default="false" target="_self" translate [routerLink]="['/view', 'faqs']">FAQ's</a></li>
            <li><a prevent-default="false" target="_self" translate [routerLink]="['/view', 'policies']">Privacy Policies</a></li>
            <li><a prevent-default="false" target="_self" translate [routerLink]="['/view', 'terms']">Terms & Conditions</a></li>
          </ul>
        </div>
        <div>
          <div class="h4">Contact</div>

          <a href="mailto:support@plutuseducation.com">support@plutuseducation.com</a>
        </div>
        <div>
          <div class="h4">Noida Campus</div>
          <p>D8, Block D, Sector 3, Noida, Uttar Pradesh 201301</p>
        </div>
        <div>
          <div class="h4">Follow us on</div>
          <ul class="category-item">
            <li class="fl-social">
              <a href="//www.facebook.com/profile.php?id=61559833956142" target="_blank" rel="noopener"
                class="tb-fb d-inline-block align-top">
                <svg width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#facebook"></use>
                </svg>
              </a>
              <a href="//www.linkedin.com/company/plutus-education/" target="_blank" rel="noopener"
                class="tb-li d-inline-block align-top">
                <svg class="d-inline-block align-top" width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#linkdin"></use>
                </svg>
              </a>
              <a href="//www.instagram.com/plutus_education_?igsh=MXd2b3JqZzJ1MHJvcg==" target="_blank" rel="noopener"
                class="tb-ig d-inline-block align-top">
                <svg class="d-inline-block align-top" width="18" height="18" fill="#86a1ae">
                  <use xlink:href="#instagram"></use>
                </svg>
              </a>
              <a href="//www.youtube.com/@PlutusEducation" target="_blank" rel="noopener"
                class="tb-yt d-inline-block align-top">
                <svg class="d-inline-block align-top" width="20" height="20" fill="#86a1ae">
                  <use xlink:href="#youtube"></use>
                </svg>
              </a>
            </li>
          </ul>
 
        </div>
      </div>
      <div class="divider"></div>
      <div class="copyright-wrapper">
        <div class="copyright">Copyright © PlutusEducation.com.: All rights reserved</div>
        <a prevent-default="false" target="_self" translate [routerLink]="['/view', 'terms']">Terms</a>
        <a prevent-default="false" target="_self" translate [routerLink]="['/view', 'policies']">Privacy</a>
      </div>
    </div>

  </footer>
</ng-container>