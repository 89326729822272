import { GAEventService } from '@angular-commons/core/services/ga-event.service';
import { Component, Input, OnInit, Output ,EventEmitter,ViewChild,ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";

@Component({
  selector: 'scrollable-tabs',
  templateUrl: './scrollable-tabs.component.html',
  styleUrls: ['./scrollable-tabs.component.scss']
})
export class ScrollableTabsComponent implements OnInit,OnChanges {

  @Input() tabs:any=[];
  @Output() selectedTab= new EventEmitter<string>();
  @Input() scrollWidth:any=200;
  @Input() currentTabId?:string;
  @Input() hasLinks:boolean=false;
  @Input() boundary?:ElementRef;
  @Input() type:string='default';
  @Input() useWebDesign:boolean=false;
  @Input() showLogo = true;
  @Input() isCurrentAffairs:boolean=false;

  isMobile: boolean = this.platformService.isMobile();
  isServer:boolean = isServer();
  maxScrollWidth:any;
  isLoggedIn: boolean = this.platformService.isLoggedIn();
  maxScrollCount:number=0;
  currentScrollCount:number=0;
  stickyTopMargin:number;
  
  @ViewChild('scrollContainer') scrollingContainer: ElementRef;

  constructor(private platformService: PlatformService,
    private gaEventService:GAEventService) { }

  ngOnInit(): void {
    this.stickyTopMargin=this.isCurrentAffairs ? 120 : 60;
  }
  // adding setTimeout so that we let html change after the tabs arrive. if setTimeout is not used, the tabs will get initialised but buttons to scroll right/left dont appear
  ngOnChanges(changes:SimpleChanges) {
    if(!isServer() && changes?.tabs?.currentValue?.length){
      setTimeout(() => {
        this.setMaxScrollWidth(changes.tabs.currentValue);
      },0);
    }
    if(changes?.currentTabId?.currentValue) {
      this.currentTabId = changes.currentTabId.currentValue;
    }
  }


  scrollLeft() {
    if(this.currentScrollCount ===0 ){
      this.scrollingContainer.nativeElement.scrollLeft=0;
      return;
    }
    this.currentScrollCount--;
    let scroll=this.scrollingContainer.nativeElement.scrollLeft - this.scrollWidth;
    this.scrollingContainer.nativeElement.scrollTo({ left: (scroll), behavior: 'smooth' });
  }

  scrollRight() {
    if(this.currentScrollCount >= this.maxScrollCount  ){
      this.scrollingContainer.nativeElement.scrollLeft=this.maxScrollWidth;
      this.currentScrollCount = this.maxScrollCount;
      return;
    }
    this.currentScrollCount++;
    let scroll=this.scrollingContainer.nativeElement.scrollLeft + this.scrollWidth;
    this.scrollingContainer.nativeElement.scrollTo({ left: (scroll), behavior: 'smooth' })
  }

  ngAfterViewInit() {
    if(this.tabs && this.tabs.length){
        this.setMaxScrollWidth(this.tabs);
    }
  }

  selectTab(tab){
    this.selectedTab.emit(tab.id);
  }

  onScroll(e){
    this.currentScrollCount=Math.floor(this.maxScrollCount - ((this.maxScrollWidth-this.scrollingContainer.nativeElement.scrollLeft)/this.scrollWidth));
  }

  setMaxScrollWidth(tabs){
    if(tabs && tabs.length && this.scrollingContainer && this.scrollingContainer.nativeElement && !isServer()){
      this.maxScrollWidth = this.scrollingContainer.nativeElement.scrollWidth-this.scrollingContainer.nativeElement.clientWidth;
      if(this.maxScrollWidth){
        this.maxScrollCount=Math.ceil((this.maxScrollWidth)/this.scrollWidth);
      }
    }
  }
}
