import {Component, HostListener, OnInit, Renderer2} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {Store} from "@ngrx/store";
import { scrollToSpecificElement } from '@angular-commons/shared/utility/dom';
import {PlatformService} from "@core/services/platform-service";
import {constructOnBoardingUrl, isServer} from "@shared/utility/tb-common";
import { filter } from 'rxjs/operators';

@Component({
  selector: "plutus-header",
  templateUrl: "./plutus-header.html",
  styleUrls: ["./plutus-header.scss"],
})
export class PlutusHeader implements OnInit {
  isLoggedIn: boolean = this.platformService.isLoggedIn();
  isMobile: boolean = this.platformService.isMobile();
  isServer: boolean = isServer();
  loginUrl = "/login";
  signupUrl = "/login?tile=signup";
  showHeaderMweb = false;
  mwebHeaderHandler = () => {};
  activePageType:string = '';
  public targetDropdownTouched = false;
  currentPath = this.platformService.getPathName();
  showStickyRequestCallback: boolean = ['/','/home'].includes(this.currentPath);
  plutusLeadId:string = '664dcc3195ddb8a00d7da7d3';
  plutusLeadProdName:string = 'ACCA Coaching Program - Applied Skill Level';

  

  constructor(
      private store: Store<{}>,
      private renderer: Renderer2,
      private platformService: PlatformService,
      private router: Router,
      private route: ActivatedRoute
    ) {
    this.platformService.pageDetailsUpdated.subscribe(()=>{
        this.loginUrl = constructOnBoardingUrl({
          referrer: this.platformService.getPageText(), 
          referrerType: this.platformService.getPageType(), 
          hideSocialSignups:true,
          skipOnboarding:true,
          hideAppSignup:true,
          isPlutus:true}, 
          true, this.platformService.href,true);
        this.signupUrl = constructOnBoardingUrl({
          referrer: this.platformService.getPageText(), 
          referrerType: this.platformService.getPageType(),
          hideSocialSignups:true,
          skipOnboarding:true,
          hideAppSignup:true,
          isPlutus:true
        }, false, this.platformService.href,true);
    });
  }

  ngOnInit() {
    /*this.store.select(selectActivePageType).subscribe((type)=>{
      this.activePageType = type;
    });*/
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
      let currentUrl = this.router.url;
      if (currentUrl) {
        this.activePageType = currentUrl;
        if(currentUrl === "/programs") {
          this.scrollTo('#explorePrograms');
        } 
        if (currentUrl === "/webinar") {
          this.scrollTo('#masterClassCards');
        }
      }else{
        throw Error('invalid url')
      }
    });
 }

  openLoginParams(){
    let pathName = this.platformService.getPathName();
    let couponValue=this.platformService.getQueryParamValue('coupon');
    let couponParam = '';
    if(couponValue){
      couponParam='?coupon='+couponValue;
    }
    let params = {
      redirect_url: pathName === "/" ? "/home" : pathName + couponParam,
      referrer: this.platformService.getPageText(),
      referrerType: this.platformService.getPageType(),
      hideSocialSignups:true,
      skipOnboarding:true,
      hideAppSignup:true,
      isPlutus:true,
    };
    return params;
  }

  openLogin(event,isLogin){
    event.preventDefault();
  }

  toggleHeaderMweb(event?) {
    this.targetDropdownTouched = true;
    if(!this.showHeaderMweb) {
      this.mwebHeaderHandler = this.renderer.listen(document, 'click', () => {this.toggleHeaderMweb()});
    }
    else {
      this.mwebHeaderHandler();
    }

    if(event) {
      event.stopPropagation();
    }

    this.showHeaderMweb = !this.showHeaderMweb;
  }

scrollTo(id) {
  if(!isServer()){
    setTimeout(()=>{
      if(this.isMobile){
        scrollToSpecificElement(id,50)
      }else{
        scrollToSpecificElement(id)
      }
    }, 500)
  }
}

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    if(window.scrollY <100 && !this.isLoggedIn){
      this.activePageType = '/';
    }else {
      this.activePageType = '/home';
    }
  }
}
