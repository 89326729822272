import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {_AttemptedTest} from "@models/entity/tests/test.adapter";
import {getCustomMap, getInMultipleOfK} from "@shared/utility/tb-common";
import { languageModifier, processTestProgress} from "@shared/utility/test-utilities";
import { PurchaseInfo } from "../purchaseInfo";
import { processIsTest } from "../live-page/live-page.adapter";

export function processOverallCutoffs(cutOffObj) {
    if(cutOffObj && cutOffObj.overAll && cutOffObj.overAll.cutOffs && cutOffObj.overAll.cutOffs.length > 0) {
        return getCustomMap(cutOffObj.overAll.cutOffs, 'category');
    }
    return null;
}

export class _ClickPopup extends baseModel {
    @propertyMaps('link', _.str)
    public link;

    @propertyMaps('image', _.str)
    public image;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('canDismiss', _.bool)
    public canDismiss;
}
setupModel(_ClickPopup, {type: 'target-page'});

export class _Test extends baseModel{

    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('isLive', _.bool)
    public isLive;

    @propertyMaps('availFrom', _.str)
    public availFrom;

    @propertyMaps('availTill', _.bool)
    public availTill;

    @propertyMaps('startTime', _.date)
    public startTime;

    @propertyMaps('endTime', _.date)
    public endTime;

    @propertyMaps('languages', _.arr)
    public languages;

    @propertyMaps('languages', languageModifier(', ',false))
    public languagesStr;

    @propertyMaps('pdfLanguages', _.arr)
    public pdfLanguages;

    @propertyMaps('pdfLanguages', languageModifier(', ',false))
    public pdfLanguagesStr;

    @propertyMaps('questionCount', _.num)
    public questionCount;

    @propertyMaps('totalMark', _.num)
    public totalMark;

    @propertyMaps('duration', _.num)
    public duration;

    @propertyMaps('totalAttempts', _.num)
    public totalAttempts;

    @propertyMaps('isFree', _.bool)
    public isFree;

    @propertyMaps('isSolutionPresent', _.bool)
    public isSolutionPresent;

    @propertyMaps('hideMarks', _.bool)
    public hideMarks;

    @propertyMaps('onClickPopup', objModel(_ClickPopup),_ClickPopup)
    public onClickPopup;

    @propertyMaps('hasTypingQuestions',_.bool)
    public hasTypingQuestions;

    @propertyMaps('hasAccess',_.bool)
    public hasAccess;

    @propertyMaps('purchaseInfo',arrayModel(PurchaseInfo),PurchaseInfo)
    public purchaseInfo;

    @propertyMaps('reattemptPurchaseInfo',arrayModel(PurchaseInfo),PurchaseInfo)
    public reattemptPurchaseInfo;

    @propertyMaps('target', _.arr)
    public target;

    @propertyMaps()
    public testUrl;

    @propertyMaps('primaryTarget',_.obj)
    public primaryTarget;

    @propertyMaps()
    public analysisUrl;

    @propertyMaps()
    public solutionsUrl;

    @propertyMaps()
    public statusUrl;

    @propertyMaps('progress', objModel(_AttemptedTest))
    public progress;

    @propertyMaps('isPdfAvailable', _.bool)
    public isPdfAvailable;

    @propertyMaps('isTestAvailable', _.bool)
    public isTestAvailable;

    @propertyMaps()
    public isQuiz;

    @propertyMaps('isQuiz', processIsTest)
    public isTest;

    @propertyMaps('pdf', _.str)
    public pdf;

    @propertyMaps('pdfId', _.str)
    public pdfId;

    @propertyMaps('cutOffs', _.obj)
    public cutOffs;

    @propertyMaps('cutOffs', processOverallCutoffs)
    public overallCutoffs;

    @propertyMaps('totalAttempts', getInMultipleOfK)
    public testSeriesEnrolledStr;

    @propertyMaps('isAnalysisGenerated', _.bool)
    public isAnalysisGenerated;

    @propertyMaps('analysisAfter', _.num)
    public analysisAfter;

    @propertyMaps('hasSkippableSections', _.bool)
    public hasSkippableSections;

    @propertyMaps('labelTags', _.arr)
    public labelTags;

    public isResultOut;

    public isResumable;

    public isReattemptable;

    public pdfUrl;

    public isRegistered;

    public hasTestExpired;

    public hasTestStarted;

    public testStartStr;

    public showExpiryInfo;

    public hasRegistered;

    public liveTestTimeString;
    
    @propertyMaps('specificExams',_.arr)
    public specificExams;
}
setupModel(_Test,{type:'target-page'});

export class _TestSubmissions extends baseModel {
    @propertyMaps('resumableTests', _.arr)
    public resumableTests;

    @propertyMaps('tests', pipeModel(arrayModel(_AttemptedTest), processTestProgress), _AttemptedTest)
    public tests;
}
setupModel(_TestSubmissions, {type: 'tests'});

export class TestSubmissions extends baseModel {
    @propertyMaps()
    public resumableTests;

    @propertyMaps()
    public tests;
}
setupModel(TestSubmissions, {});

export class GetAttemptedTestApi{
    static apiEndpoint = 'v1/tests/submissions';

    static projection;
    static get  __projection(){ 
        if(!GetAttemptedTestApi.projection){
           GetAttemptedTestApi.projection = JSON.stringify(new SourceProjectionMapper(_TestSubmissions).map());
        }
        return GetAttemptedTestApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetAttemptedTestApi.apiEndpoint,{__projection:GetAttemptedTestApi.__projection, ...params});
    }
}
