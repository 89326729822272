import { APIConstants } from '../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {capitalize} from "@shared/utility/string-utilities";
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import { PROJECT_PLUTUS, PROJECT_TESTBOOK } from '@angular-commons/shared/utility/constants';

@Injectable()
export class NetworkService {
    private baseUrl: string = APIConstants.APIPrefix;
    private jarvisApiEndpoint:string=APIConstants.JarvisAPIPrefix;
    private nodeApiEndpoint: string = isServer() ? APIConstants.NodeAPIPrefix : '/api/';


    constructor(private http: HttpClient, private platformService: PlatformService) {
    }

    get(relativeUrl, params?, endpointType?, forceAuthOnServer = false) {
        const headers = this.generateHeaders(forceAuthOnServer);
        let apiBase;
        if(endpointType){
            if(endpointType == 'node'){
                apiBase =  this.nodeApiEndpoint;
            }else if(endpointType == 'jarvis'){
                apiBase =  this.jarvisApiEndpoint;
            }else if(endpointType == 'crm'){
                apiBase = APIConstants.OCRMAPIPrefix;
            }else if(endpointType == 'cloud'){
                apiBase = APIConstants.CloudBucketDataPrefix;
            }else if(endpointType == 'personalised-web-banners'){
                apiBase = APIConstants.pwbApiEndpoint;
            }
        }else{
            apiBase =  this.baseUrl;
        }

        if(!params) {
            params = {};
        }
       let lang = (params.language) ? params.language : this.platformService.getSiteLang()
        params = {...params, language: capitalize(lang)};
        return this.http.get(`${apiBase + relativeUrl}`, {headers: new HttpHeaders(headers), params});
    }

    post(relativeUrl, body, params?, forceAuthOnServer = false) {
        const headers = this.generateHeaders(forceAuthOnServer);
        return this.http.post(`${this.baseUrl + relativeUrl}`,  body ,{headers , params});
    }

    patch(relativeUrl, body, params?, forceAuthOnServer = false) {
        const headers = this.generateHeaders(forceAuthOnServer);
        return this.http.patch(`${this.baseUrl + relativeUrl}`,  body ,{headers , params});
    }

    put(relativeUrl, body, params?, forceAuthOnServer = false) {
        const headers = this.generateHeaders(forceAuthOnServer);
        return this.http.put(`${this.baseUrl  + relativeUrl}`, body, {headers, params});
    }

    delete(relativeUrl, body, params?, forceAuthOnServer = false) {
        const headers = this.generateHeaders(forceAuthOnServer);
        return this.http.delete(`${this.baseUrl  + relativeUrl}`, {headers, params});
    }

    upload(relativeUrl, file, body?, params? ,httpOptions:any={}, forceAuthOnServer = false, skipBaseUrl = false,skipHeaders= false) {
        if(!skipHeaders){
            httpOptions.headers=this.generateHeaders(forceAuthOnServer)
        }

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(`${ (!skipBaseUrl ? this.baseUrl : "") + relativeUrl}`, formData, httpOptions);
    }

    generateHeaders(forceAuth = false) {
        const headers = {};

        if((!isServer() || forceAuth ) && this.platformService.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.platformService.getToken();
        }
        headers['X-Tb-Client'] = 'web,' + APIConstants.APIVersion;
        if(this.platformService.currentProjectName() == PROJECT_PLUTUS){
            headers['source'] = PROJECT_PLUTUS
        }else{
            headers['source'] = PROJECT_TESTBOOK
        }
        return headers;

    }

    getStats() {
        return this.get('v1/stats/summary');
    }
}
