import {Component, Inject, OnInit} from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {AuthenticationService} from "@core/services/authentication.service";
import {Store} from "@ngrx/store";
import {setOpenInAppDialogVisibility} from "@core/application-state/app.actions";
import {selectOpenInAppDialog} from "@core/application-state/app.selectors";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {inIframe, isServer} from "@shared/utility/tb-common";
import {DOCUMENT} from "@angular/common";
import {clientOnly} from "@shared/utility/platform-decorators";
import {populateOnboardingParams} from "@shared/utility/onboarding";
import {getCurrentVisitSource} from "@shared/utility/user";
import { ContinueInAppPageMap, getAppInstallPopupPageDeeplink } from '@angular-commons/shared/utility/android-app-utilities';

declare var window:any;

@Component({
  selector: 'open-in-app-dialog',
  templateUrl: './open-in-app-dialog.component.html',
  styleUrls: ['./open-in-app-dialog.component.scss']
})
export class OpenInAppDialogComponent implements OnInit {
  isMobile: boolean = this.platformService.isMobile();
  yolo;
  showOpenInAppDialog;
  openInAppDialogDeeplink;
  openInAppDialogStateKey = makeStateKey('openInAppDialog');
  isServer = isServer();
  appInstallDeeplinkFallback = ContinueInAppPageMap['HomePage']?.deeplinkPath;
  appInstalTrackingInfoFallback = ContinueInAppPageMap['HomePage']?.trackingInfo;
  appInstallFallbackUrl = getAppInstallPopupPageDeeplink(this.appInstalTrackingInfoFallback, '', this.appInstallDeeplinkFallback);

  constructor(private platformService: PlatformService, private auth: AuthenticationService, private store: Store<{}>,
              private transferState: TransferState, @Inject(DOCUMENT) private document: Document) {

    let showDialog = false;
    this.isOpenInAppDialogHidden() ? this.closeopenInAppDialog(true): showDialog = true;
    this.store.dispatch(setOpenInAppDialogVisibility({show: showDialog}));

    let openInAppDialog:any = this.transferState.get(this.openInAppDialogStateKey, null);

    if(!this.isServer && openInAppDialog && Object.keys(openInAppDialog).length > 0) {
      this.showOpenInAppDialog = openInAppDialog.show;
      this.openInAppDialogDeeplink = openInAppDialog.deeplink || this.appInstallFallbackUrl;
    }

    this.store.select(selectOpenInAppDialog).subscribe(data => {
      this.showOpenInAppDialog = data.show;
      this.openInAppDialogDeeplink = data.deeplink || this.appInstallFallbackUrl;
      data.show && this.document.body.classList.add('defer-yolo-init');
      if(this.isServer) {
        this.transferState.set(this.openInAppDialogStateKey, data);
      }
    });
  }

  ngOnInit(): void {
  }

  @clientOnly()
  closeopenInAppDialog(silent=false) {
    this.store.dispatch(setOpenInAppDialogVisibility({show: false}));
    this.platformService.setCookie('hideOpenInAppDialog', true, 1);
    if(!this.platformService.isLoggedIn() && !silent){
      const signupDetails = this.auth.getSignupDetails(getCurrentVisitSource());
      this.auth.triggerTrueCaller(populateOnboardingParams({referrer: signupDetails.page, referrerType: signupDetails.pageType}, this.platformService.getUserAgent(), this.platformService.getPathName()),{forceCampaign :"ContinuePromptTruecaller"}).catch(()=>{});
    }
  }

  isOpenInAppDialogHidden() {
    const noBanner = this.platformService.getQueryParamValue('nobanner');
    const hideDialog = !!this.platformService.getQueryParamValue('hideOpenInAppDialog');
    const isMgoalPage = this.platformService.href.includes('/mgoal/');
    const isLoginPage = this.platformService.getPathName().includes('/login');
    const isPassWrappedPage = this.platformService.getPathName().includes('/pass-wrapped');
    const isTermsPage = this.platformService.getPathName().includes('-coaching/terms-and-condition');
    const isWebView = this.platformService.getQueryParamValue('inFrame');
    const isInFrame = inIframe();
    const isPlutus = this.platformService.currentProjectName() == 'plutus';
    //always show popup for logged-out users
    if(!this.platformService.isLoggedIn()){
        return hideDialog || (noBanner && (noBanner === '1' || noBanner === 'true')) || isMgoalPage || isLoginPage || isInFrame || (isTermsPage && isWebView == '1') || isPassWrappedPage || isPlutus;
    }
    return hideDialog || !!this.platformService.getCookie('hideOpenInAppDialog') || (noBanner && (noBanner === '1' || noBanner === 'true')) || isMgoalPage || isLoginPage || isInFrame || (isTermsPage && isWebView == '1') || isPassWrappedPage || isPlutus;
  }

}
