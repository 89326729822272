import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output
} from '@angular/core';
import {Store} from "@ngrx/store";
import {
  getTargetsOfSuperGroup,
  getPypTargetsOfSuperGroup,
  getTargetSuperGroups,
  getPypTargetSuperGroups, getPypTargetsOfSuperGroupSuccess
} from "@core/application-state/app.actions";
import {
  selectPypSuperGroups,
  selectPypTargets,
  selectSuperGroups,
  selectTargets
} from "@core/application-state/app.selectors";
import {TargetSlug} from "@models/entity/targets/target.slug.adapter";
import {TargetSuperGroupModel} from "@models/entity/targets/target-supergroup.adapter";
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {makeStateKey, TransferState} from "@angular/platform-browser";

@Component({
  selector: 'target-list',
  templateUrl: './target-list.component.html',
  styleUrls: ['./target-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TargetListComponent implements OnInit {
  @Input() pageType?;
  @Input() onPage?;
  @Input() coldbootParams?;
  @Input() tabsType? : string = '';
  @Input() title = 'POPULAR_EXAMS';
  @Input() description? = 'GET_EXAM_READY';
  @Input() scrollWidth:number=200;
  @Input() routeSuffix? = '';
  @Input() fullNavigation = true;
  @Output() onTargetClick? = new EventEmitter();
  @Input() cssModifierClass:string;

  public isMobile: boolean = this.platformService.isMobile();
  public isServer: boolean = isServer();
  public haveTargetsLoaded: boolean = false;

  public selectedSuperGroupIndex = 0;
  public startLimit = 0;
  public endLimit = 12;
  public currEndLimit = this.endLimit;

  // vars for mweb
  public accordionIndex = -1;
  public targetsSupergroupMap: any = {};


  public showMwebModal : boolean = false;
  public targets: Array<TargetSlug> = [];
  public superGroups: Array<TargetSuperGroupModel> = [];
  public superGroupsList = [];
  public selectedSuperGroupId:string = '';
  superGroupUrl:string = '';

  supergroupsList$ = this.store.select(selectSuperGroups);
  private supergroupslistKey:any = makeStateKey('Supergroupslist');

  targetsList$ = this.store.select(selectTargets);
  private targetslistKey:any = makeStateKey('Targetslist');

  pypSupergroupsList$ = this.store.select(selectPypSuperGroups);
  private pypSupergroupsListKey:any = makeStateKey('PypSupergroupsList');

  pypTargetsList$ = this.store.select(selectPypTargets);
  private pypTargetsListKey:any = makeStateKey('PypTargetsList');

  defaultTargetLogo = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  imgAltSuffix = '';
  coldbootsub;

  constructor(private store: Store<{}>,
              private transferState: TransferState,
              private platformService: PlatformService,
              private changeDetectorRef : ChangeDetectorRef) {
    this.store.dispatch(getTargetSuperGroups());
  }

  ngOnInit() {
    if (!this.isServer && this.coldbootParams) {
        this.coldbootsub = this.coldbootParams.then((params) => {
          const awaitIdArr = params.awaitId.split('_');
          if(awaitIdArr.length && awaitIdArr[0] === 'identifierswitchsupergroup') {
            this.switchToSuperGroup(awaitIdArr[1], +awaitIdArr[2]);
            this.changeDetectorRef.markForCheck();
          }
        });
    }

    if (this.pageType == 'pyp') {
      this.imgAltSuffix = 'Previous Year Paper';
      const pypSuperGroupsListState:any = this.transferState.get(this.pypSupergroupsListKey, null);
      const pypTargetsListState:any = this.transferState.get(this.pypTargetsListKey, null);

      if (!this.isServer && pypSuperGroupsListState) {
        this.superGroups = pypSuperGroupsListState;
      }
      if (!this.isServer && pypTargetsListState) {
        this.store.dispatch(getPypTargetsOfSuperGroupSuccess({payload: pypTargetsListState}));
        this.targets = pypTargetsListState;
        this.haveTargetsLoaded = true;
      }

      if (!pypSuperGroupsListState || this.isServer) {
        this.store.dispatch(getPypTargetSuperGroups({pageType: this.pageType}));
      }

      this.pypSupergroupsList$.subscribe(data => {
        if (data.length > 0) {
          this.superGroups = data;
          if (this.isServer) {
            this.transferState.set(this.pypSupergroupsListKey, this.superGroups);
          }
          this.changeDetectorRef.markForCheck();
        }
      });

      this.pypTargetsList$.subscribe(data => {
        if (data) {
          this.targets = data;
          this.haveTargetsLoaded = true;
          if (this.isServer) {
            this.transferState.set(this.pypTargetsListKey, this.targets);
          }
          this.changeDetectorRef.markForCheck();
        }
      });

    }
    else {

      const superGroupsListState = this.transferState.get(this.supergroupslistKey, null);
      const targetsListState = this.transferState.get(this.targetslistKey, null);

      if (!this.isServer && superGroupsListState) {
        this.superGroups = superGroupsListState;
      }
      if (!this.isServer && targetsListState) {
        this.targets = targetsListState;
      }

      if (!superGroupsListState || this.isServer) {
        this.store.dispatch(getTargetSuperGroups());
      }
      if (!targetsListState && this.isServer) {
        this.targets = targetsListState;
      }

      this.targetsList$.subscribe((value: Array<TargetSlug>) => {
        if(value && value.length > 0) {
          this.targets = value;
          let target = value[0];
          this.haveTargetsLoaded = true;
          for(let superGroup of target.properties.superGroupInfo) {
            this.targetsSupergroupMap[superGroup._id] = value;
          }
          this.changeDetectorRef.markForCheck();
        }
      });

      this.supergroupsList$.subscribe((value: Array<TargetSuperGroupModel>) => {
      if(value && value.length > 0){
        this.superGroups = value;
          this.superGroupsList =  this.superGroups.map(group =>
            {
              let superGroupProps = {};
              superGroupProps['title'] = group.properties.title;
              superGroupProps['id'] = group._id;
              superGroupProps['logo'] = group.properties.logo;
              return superGroupProps;
            });
             this.selectedSuperGroupId = this.superGroupsList.length > 0 && this.superGroupsList[0].id;
             this.superGroupUrl = this.selectedSuperGroupId ? 'exams?selectedSuperGroup='+this.selectedSuperGroupId : 'exams';
            this.changeDetectorRef.markForCheck();
        }
      });
    }
  }

  switchToSuperGroup(superGroupId, ind = 0) {
    this.selectedSuperGroupId = superGroupId || '';
    this.selectedSuperGroupIndex = ind;
    this.superGroupUrl = this.selectedSuperGroupId ? 'exams?selectedSuperGroup='+this.selectedSuperGroupId : 'exams';
    if(this.pageType === 'pyp') {
      this.currEndLimit = this.endLimit;
      
      this.store.dispatch(getPypTargetsOfSuperGroup({superGroupId, pageType: 'pyp'}));
    }
    else {
      
      this.store.dispatch(getTargetsOfSuperGroup({superGroupId}));
    }
  }

  loadMoreTargets(targetsLength) {
    this.currEndLimit = targetsLength;
  }

  trackByTargetID(index: number, target: any): string {
    return target.id;
  }

  toggleAccordion(index) {
    if (index != -1 && index === this.accordionIndex) {
      this.accordionIndex = -1;
    } else {
      this.accordionIndex = index;
    }
  }

  targetClick(target) {
    this.onTargetClick.emit(target);
  }

  showMwebPage(superGroupId, ind){
    this.showMwebModal = true;
    this.switchToSuperGroup(superGroupId, ind);
  }
  hideMwebModal(){
    this.showMwebModal = false;
  }
}
