<header class="header">
  <!-- Main Header for logged out state -->
  <div class="header__post" [ngClass]="{'open': showHeaderMweb}" >
      <div class="logo">
          <div class="logo__hamburger js-trigger-hamburger-button" (click)="toggleHeaderMweb($event)"><span></span></div>
          <ng-container *ngIf="!isLoggedIn">
            <a class="logo__testbook" routerLink="/" *ngIf="!isMobile">
              <img src="./assets/img/ui-kit/plutus-logo.svg" alt="plutus-logo">
            </a>
            <a class="logo__testbook_mobile" routerLink="/" *ngIf="isMobile">
                <img src="./assets/img/ui-kit/plutus-logo.svg" alt="plutus-logo">
            </a>
          </ng-container>
          <ng-container *ngIf="isLoggedIn">
            <a class="logo__testbook" routerLink="/home" *ngIf="!isMobile">
              <img src="./assets/img/ui-kit/plutus-logo.svg" alt="plutus-logo">
            </a>
            <a class="logo__testbook_mobile" routerLink="/home" *ngIf="isMobile">
                <img src="./assets/img/ui-kit/plutus-logo.svg" alt="plutus-logo">
            </a>
          </ng-container>
          
        <a href="{{signupUrl}}"  *ngIf="isMobile && !isLoggedIn"
        [attr.data-onboarding-params]="openLoginParams() | json"
        class="btn btn-outline-dark ml-auto" translate>Login</a>

          <a *ngIf="isMobile && isLoggedIn" href="/logout" class="btn btn-outline-dark ml-auto">Logout</a>

      </div>
      <ul class="action-nav" *ngIf="!isMobile">
        <li *ngIf="!isLoggedIn">
          <a href="{{signupUrl}}" 
             [attr.data-onboarding-params]="openLoginParams() | json"
             class="btn btn-outline-dark ui-js-login" translate>Login</a>
         </li>
          <li *ngIf="!isLoggedIn">
            <request-callback [fireGaEvent]="true" [bannerType]="'headerbtn'" id="request-callback" [from]="'plutus'" [plutusLeadId]="plutusLeadId" [plutusLeadProdName]="plutusLeadProdName"></request-callback>
          <li *ngIf="isLoggedIn">
            <a href="/logout" *ngIf="isLoggedIn" class="btn btn--outline btn--hover-brand">Logout</a>
          </li>
          
      </ul>

      <div class="footer-navigation" *ngIf="!isLoggedIn && isMobile && showStickyRequestCallback">
        <div class="footer-navigation__container">
          <request-callback [fireGaEvent]="true" [bannerType]="'headerbtn'" id="request-callback" [from]="'plutus'" [plutusLeadId]="plutusLeadId" [plutusLeadProdName]="plutusLeadProdName"></request-callback>
        </div>
      </div>

      <div class="container">
          <!-- Main Header logged out case-->
          <ul class="main-nav">
              <li *ngIf="!isLoggedIn">
                <a routerLink="" [ngClass]="{'active-link': activePageType === '/'}">
                    <img *ngIf="isMobile" src="/angular/assets/img/plutus-home.png" alt="success" class="header-img" />
                    Home
                  </a>
              </li>
              <li *ngIf="isLoggedIn">
                <a routerLink="/home" [ngClass]="{'active-link': activePageType === '/home'}">
                  <img *ngIf="isMobile" src="/angular/assets/img/plutus-home.png" alt="success" class="header-img" />
                  Home
                </a>
            </li>
              <li>
                <a routerLink="/programs" [ngClass]="{'active-link': activePageType === '/programs'}">
                  <img *ngIf="isMobile" src="/angular/assets/img/plutus-program.png" alt="success" class="header-img" />
                  Programs
                </a>
            </li>
            <li>
              <a routerLink="/webinar" [ngClass]="{'active-link': activePageType === '/webinar'}">
                <img *ngIf="isMobile" src="/angular/assets/img/plutus-webinar.png" alt="success" class="header-img" />
                Webinar
              </a>
          </li>
          <li>
            <a target="_self" href="/blog" [ngClass]="{'active-link': activePageType === '/blog'}">
              <img *ngIf="isMobile" src="/angular/assets/img/plutus-blog.png" alt="success" class="header-img" />
              Blog
            </a>
        </li>
          </ul>
      </div>
      <div class="header-backdrop js-trigger-header-backdrop"></div>
  </div>
</header>

