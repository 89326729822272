import {AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, Inject, NgZone, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import localeIn from '@angular/common/locales/en-IN';
import {DOCUMENT, registerLocaleData} from '@angular/common';
import {APIConstants} from "../../../angular-commons/environments/environment";
import { NavigationEnd, NavigationStart, Router} from "@angular/router";
import {PlatformService} from "../../../angular-commons/core/services/platform-service";
import {
    getLocalStorage,
    getPageText, 
    hideLivePanel, 
    inIframe,
    isServer,
    removeCloseBtnFromModal,
    removeLocalStorage,
    showLivePanel,
    showLoader
} from "../../../angular-commons/shared/utility/tb-common";
import { 
    selectSidebarState, 
    selectStudent, 
    selectGoals, 
    selectOpenInAppDialog, 
    selectTransactionPopupAction, 
    selectDisableAutoCouponApply 
} from '@angular-commons/core/application-state/app.selectors';
import {AuthenticationService} from "../../../angular-commons/core/services/authentication.service";
import { ExposeNgrxToWindow } from '@angular-commons/shared/utility/ExposeNgrxToWindow';
import { Store } from '@ngrx/store';
import {GAEventService} from "@core/services/ga-event.service";
import {ProductAccessService} from "@core/services/product-access.service";
import {clientOnly} from "@shared/utility/platform-decorators";
import {
    resetOnNavigation, 
    getGoals, 
    transactionPopupAction, 
    stickyFooterType, 
    setStudentGCLID, 
    showAlert, 
    setEmiIframeState
} from '@angular-commons/core/application-state/app.actions';
import {TransferStateManager} from "../../../angular-commons/core/services/transferStateManager";
import {GOAL_EXPIRY} from "../../../angular-commons/shared/utility/constants";
import {
    getGoalDashboardPageUrl, 
    getGoalSelectionPageUrl
} from "../../../angular-commons/shared/utility/goal-utility";
import {
    hideFooter, 
    hideHeader,
    hideSidebar, 
    hideStickyFooter,
    showFooter,
    showHeader,
    showSidebar, 
    showStickyFooter
} from "../../../angular-commons/core/application-state/app.actions";
import {TbcoreService} from "../../../angular-commons/core/services/tbcore.service";
import { resetCoupon } from '@angular-commons/feature-modules/coupon-code/feature-state/coupon.actions';
import {checkWaffleComponent, unbindWaffleComponents} from "../../../angular-commons/shared/utility/vanilla";
import { Observable } from 'rxjs';
import {_passModel} from "../../../angular-commons/models/entity/passes/passes.adapter";
import {skipWhile, take} from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getQueryStringFromParams, getUrlQueryParams } from '@angular-commons/shared/utility/url';
import { StudentService } from '@angular-commons/core/services/student.service';
import { allClientOnlyPaths } from 'angular-tb-ssr/server-constants';
import { loggedoutPurchaseModalState } from './route-modules/logged-out-purchase/feature-state/logged-out-purchase-iframe.selector';
import { LeadGenerationService } from '@angular-commons/core/services/lead-generation.service';
import {Validator} from "@shared/utility/validator";
import { getCurrentVisitSource } from '@angular-commons/shared/utility/user';
import {populateOnboardingParams} from "../../../angular-commons/shared/utility/onboarding";
import {emiIframeSelector} from "./route-modules/emi/feature-state/emi-iframe.selector";
import { GAService } from '@angular-commons/core/services/ga.service';

declare var $:any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,AfterViewInit{

    isLoggedIn = this.platformService.isLoggedIn();
    isMobile = this.platformService.isMobile();
    isIosDevice = this.platformService.isIosDevice();
    isMPage = false;
    showSidebar: boolean = true;
    singleton : boolean = true;
    showAppHeader : boolean = true;
    goals$: any = this.store.select(selectGoals);
    goalsTransferState$ : any;
    goals : any;
    student : any;
    targetEnrolled:boolean;
    serverUserTargetEnrolled:boolean;
    isServer = isServer();
    disableAutoApplyCoupon:boolean=false;
    selectDisableAutoCouponApply$:Observable<any>;
    gclid:string
    loggedoutPurchaseModalState$: any = this.store.select(loggedoutPurchaseModalState);
    loggedOutPurchaseModalUrl:string = "about:blank";
    loggedOutPurchaseModalIFrameURL:SafeResourceUrl;
    showLoggedOutPurchaseModal:boolean = false;
    loggedoutIFrameElem:Element;
    inIFrame:boolean = false;
    isLeadFired:boolean = false;
    emiIframeUrl: SafeResourceUrl;
    showEMIModal = false;
    emiModalType = '';
    emiIframeModalState$ = this.store.select(emiIframeSelector);
    isFromIOS: boolean = false;

    showYouTubePopup: boolean = false;


    @clientOnly()
    handleTransactionPopupActions(){
        this.store.select(selectTransactionPopupAction).subscribe(({id, payloadtype, payload}) => {
            if (id && id.length > 0) {
                if (payloadtype === "TEST_SERIES") {
                    this.platformService.redirect("/online-test-series");
                } else if (payloadtype === "GOAL_DASHBOARD") {
                    let slug = payload.goal.slug || payload.goal.properties?.slug;
                    if (slug) {
                        this.platformService.redirect(getGoalDashboardPageUrl(slug), null);
                    } else {
                        this.platformService.redirect(getGoalSelectionPageUrl(), null);
                    }
                }
                this.store.dispatch(transactionPopupAction({id: '', payloadtype: '', payload: {}}));
            }
        })
    }
    constructor(private translateService: TranslateService,
                private router: Router,
                private app: ApplicationRef,
                private platformService: PlatformService,
                private auth: AuthenticationService,
                private gaEventService:GAEventService,
                private leadGenService: LeadGenerationService,
                private store: Store<{}>,
                private studentService:StudentService,
                private transferStateManager: TransferStateManager,
                private tbCore: TbcoreService,
                private productAccess: ProductAccessService,
                private ngZone : NgZone,
                private changeDetectionRef : ChangeDetectorRef,
                private gaService:GAService,
                @Inject(DOCUMENT) private document,
                private sanitizer : DomSanitizer) {     
        this.checkForYtPopup(); 
        this.platformService.addClassToBody();  
        if(!this.isServer){
            this.gaService.updateFields(); // to set the ga params in localStorage
        }

        if(this.platformService.getCookie("justLoggedIn")==='true'){
            this.platformService.setCookie("justLoggedIn",false,-1);
            if(!isServer() && !inIframe() && !getLocalStorage('promptMessageMGoal') && !this.platformService.getQueryParamValue("trigger") && !this.platformService.getQueryParamValue("stay") && !this.platformService.getQueryParamValue("pid") && !(this.platformService.getQueryParamValue("code")) && !(this.platformService.getForceStopRedirectionText()==='true')
            && this.platformService.currentProjectName() != 'plutus'){
                // this.platformService.redirect("/super-coaching",302,true);

                this.store.select(selectStudent).pipe(skipWhile( s => !s || s.id == "-1"),take(1)).subscribe(student => {
                    if(this.platformService.getForceStopRedirectionText()==='true'){
                        return;
                    }
                    if( !_passModel.isPassActive(student.globalPassExpiry,true))
                    {
                        this.platformService.redirect("/pass",302,true);
                    }
                    else
                    {
                        this.platformService.redirect("/super-coaching",302,true);
                    }
                })

            }
        }
        if(!this.isServer && !this.isLoggedIn && this.platformService.getQueryParamValue("requireSignUp") === 'true' && this.platformService.currentProjectName() != 'plutus'){
            let redirect_url = this.platformService.getPathName() + window.location.search;
            this.tbCore.openSignUpSignInModal({redirect_url}, true);
            setTimeout(()=>{
                removeCloseBtnFromModal()
            },0)

        }

        

        if(!isServer()){
            // expose an instance of current app
            let element= document.querySelector("app-root");
            if(element){
                if(element.tbApp && element.tbApp != this){
                    element.tbApp.app.destroy()
                }
                element.tbApp = this;
            }
        }
        this.isMPage = this.platformService.getQueryParamValue('mPage') === '1';
        if(this.platformService.currentProjectName() == 'plutus'){
            if(this.isLoggedIn){
                this.store.dispatch(hideFooter());
              }
              this.platformService.hideIntercom();
              this.store.dispatch(hideSidebar());
              this.store.dispatch(hideStickyFooter());
              hideLivePanel();
        }else{
            if(!this.isMPage){
                this.store.dispatch(showHeader());
                this.store.dispatch(showFooter());
                this.store.dispatch(showSidebar());
            } else {
                this.store.dispatch(hideHeader());
                this.store.dispatch(hideFooter());
                this.store.dispatch(hideSidebar());
                this.store.dispatch(hideStickyFooter());
            }
        }
        
        this.handleTransactionPopupActions();
        this.store.select(selectSidebarState).subscribe((value) => {
                this.showSidebar = value;
            }
        );
        this.serverUserTargetEnrolled=this.platformService.getCookie('userTargetEnrolled');
        let pathname=this.platformService.getPathName();
        if(isServer() && this.serverUserTargetEnrolled != undefined && !this.serverUserTargetEnrolled && pathname != '/nps-inline' && this.platformService.currentProjectName() != 'plutus'){
            let url=`/enrollment?redirect_url=` + encodeURI(this.platformService.href);
            this.platformService.redirect(url,302);
        }

        this.store.select(selectStudent).subscribe((value) => {
                if(!isServer() && value?.targetInfo && value?.targetInfo?.length==0 && (pathname!='/enrollment' && pathname!='/onboarding' && pathname !== '/offline-center-login' && pathname != '/nps-inline') && this.platformService.currentProjectName() != 'plutus'){
                    this.targetEnrolled= false;
                    this.platformService.setCookie('userTargetEnrolled', this.targetEnrolled);
                    let url=`/enrollment?redirect_url=` + encodeURI(this.platformService.href);
                    this.platformService.redirect(url,302);
                    return;
                }else if(value?.targetInfo && value.targetInfo.length > 0 ){
                    this.targetEnrolled=true;
                    this.platformService.setCookie('userTargetEnrolled', this.targetEnrolled);
                }
            }
        );

        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd && getPageText() === 'Others') {
                this.platformService.setPageText('Others');
            }
            if(ev instanceof NavigationStart){
                let clientPath = ev.url.split('?')[0];
                this.platformService.setClientPathName(clientPath);
                if(this.getCurrentWindow() && this.getCurrentWindow()['isPageReadyEventFired']){
                    delete this.getCurrentWindow()['isPageReadyEventFired'];
                }
                if(!clientPath.startsWith("/closed-access-pdf-viewer")){
                    this.store.dispatch(resetOnNavigation({url:ev.url}));
                    if(this.platformService.currentProjectName() != 'plutus'){
                        this.store.dispatch(stickyFooterType({footerType:'default'}));
                        this.store.dispatch(showStickyFooter());
                        showLivePanel();
                    }
                    
                }
                if(clientPath.startsWith("/closed-access-pdf-viewer")){
                    this.showAppHeader = false;
                }
                if(!isServer()){
                    clearTimeout((<any>window).promotionTimer);
                    this.store.dispatch(resetCoupon());
                }
            }
            if(ev instanceof NavigationEnd && this.platformService.isLoggedIn()){
                this.auth.checkActiveSessions();
            }
            if(ev instanceof NavigationEnd && !isServer()){
                unbindWaffleComponents();
                setTimeout(()=>{
                    checkWaffleComponent("$$all");
                },100);
            }
        });
        translateService.setDefaultLang('english');
        if(!isServer()) {
            const exposeNgrxToWindow = new ExposeNgrxToWindow(this.store);

            const waitForYolo = setInterval(() => {
                if (typeof window['yoloLib'] === 'undefined') return;
                clearInterval(waitForYolo);
                this.store.select(selectOpenInAppDialog).subscribe(data => {
                    if(!data.show){
                        this.auth.onLoadInitYoloNg(APIConstants.APIPrefix);
                    }
                });
            }, 500);

            if(this.document && this.document.addEventListener) {
                this.document.addEventListener('logged-in', (e) => {
                    let data = e.detail;
                    window.scrollTo(0, 0);
                    if (data.key) {
                        window.top.location.href = data.key;
                    } else {
                        window.top.location.reload();
                    }
                });
            }
        }
    }

    setCurrentGoal(){ // sets the cookie to currently selected goal from BE
        if(this.goals && this.student && this.student.currentGoal){
            let selectedGoal = this.goals.find( goal => goal.id == this.student.currentGoal)
            if(selectedGoal && selectedGoal.properties.slug && !this.auth.studentGoalDirty){
                this.auth.setGoal({id:selectedGoal.id,slug:selectedGoal.properties.slug},GOAL_EXPIRY,false,false);
            }
        }
    }

    getAllGoals(){
        this.goalsTransferState$ = this.transferStateManager.get('allGoals', getGoals(), this.goals$,(value)=>{
            if(value){
                this.goals = value.goals;
                this.setCurrentGoal();
            }
        });
    }

    getSelectedGoal(){
        this.getAllGoals();
        this.store.select(selectStudent).subscribe( student => {
            this.student = student;
            /*if(!isServer() && this.student && this.student.goalPlanState){
                this.platformService.setCookie(TB_GOALS_PURCHASED,goalPlan.allPlansSerialiser(this.student.goalPlanState),1);
            }*/
            this.setCurrentGoal();
        })
    }

    fireLeadFromLocalStorage(student:any){
        if(!this.isLeadFired){
            let leadObj = Validator.safeJsonParse(getLocalStorage("leadObj"));
            if(leadObj && leadObj.parentId && student && student.id != '-1'){
                leadObj["mobile"] = student.mobile || "";
                leadObj["sid"] = student.id || "";
                leadObj["email"] = student.email || "";
                this.leadGenService.generateLead(leadObj);
                removeLocalStorage("leadObj");
                this.isLeadFired = true;
            }
        }
    }

    fireLeadFromQueryParams(student:any){
        let queryParams:any = getUrlQueryParams(this.platformService.href);
        if(!this.isLeadFired && queryParams?.fireLead === "true"){
            const leadObj = {
                action: queryParams?.action || '',
                type: queryParams?.type || 'other',
                parentId: queryParams?.parentId || '',
                prodId: queryParams?.prodId || '',
                client: this.platformService.isMobile() ? "mweb" : "web",
                utm: getCurrentVisitSource(),
                mobile: student.mobile  || "",
                email: student.email  || "",
                sid: student.id  || "",
            } 
            this.leadGenService.generateLead(leadObj);
            this.isLeadFired = true;
        }
    }

    ngOnInit(){
        this.isFromIOS = this.platformService.getQueryParamValue('fromIOS') || false;
        if(!isServer()){
            this.auth.onBoardingParams = populateOnboardingParams(getUrlQueryParams(this.platformService.href), this.platformService.getUserAgent(), this.platformService.getPathName());;
            this.gclid = this.platformService.getQueryParamValue('gclid');
            let isGclid = this.platformService.getCookie('gclid');
            if(this.gclid && (!isGclid || isGclid != this.gclid)){
                this.platformService.setCookie('gclid', this.gclid, 30/1440);
                if(this.platformService.isLoggedIn())
                {
                    this.store.dispatch(setStudentGCLID({gclid:this.gclid }));
                }
            }
        }
        
        this.selectDisableAutoCouponApply$=this.store.select(selectDisableAutoCouponApply);
        this.selectDisableAutoCouponApply$.subscribe(data=>{
            this.disableAutoApplyCoupon=data;
        })
        registerLocaleData(localeIn);
        if(this.platformService.isLoggedIn()) {
            this.showPromptMessage();
            this.studentService.loadStudent(true).then(value => {
                this.fireLeadFromLocalStorage(value);
                this.fireLeadFromQueryParams(value);
            });
            this.productAccess.passAndPassProActiveState().then(access => {
                let userAccess = {passAccess: false, passProAccess: false, superAccess: false};

                userAccess.passProAccess = access?.passProAccess;
                userAccess.passAccess = access?.passAccess;
                userAccess.superAccess = access?.goalSubsAccess;
                this.platformService.setCookie('userAccess', JSON.stringify(userAccess), 7);
            });
            //this.store.dispatch(getStudentMe()); //<== done already when TbCoreService is instantiated
            this.getSelectedGoal();
        }
        if(!isServer()){
            this.inIFrame = inIframe();
            if(!this.inIFrame){
                this.setLoggedOutPurchaseModalData();
                this.setEmiIframeModalData();
            }
        }
        if (this.isIosDevice){
            this.platformService.addClass('iosDevice');
        }

        window.addEventListener("message", (event)=>{
			if(event.data && event.data.source=="loggedOutModal") {
				if(event.data.closingLoggedOutModal){
                    this.loggedOutPurchaseModalUrl = "about:blank";
                    this.loggedOutPurchaseModalIFrameURL = null;
                    this.showLoggedOutPurchaseModal = false;
                    if(this.platformService.getQueryParamValue("backOnClose")){
                        window.history.back();
                    }                    
				} else {
					console.error("unexpected state for logged out purchase modal");
				}
			}
            if(event.data && event.data.source=="emiModal"){
                if(event.data.closeEmiModal){
                    this.emiIframeUrl = null
                    this.showEMIModal = false;
                    this.emiModalType = '';
                    this.platformService.removeClass('modal-open');
                    if(this.platformService.currentProjectName() != 'plutus'){
                        this.platformService.showIntercom();
                        this.platformService.showLivePanel();
                    }
                    
                }
                else if(event.data.openEmiModal){
                    const {openEmiModal,source,...data} = event.data
                    this.store.dispatch(setEmiIframeState(data));
                }
            }
		}, false);
    }

    @clientOnly()
    setLoggedOutPurchaseModalData(){
        this.loggedoutPurchaseModalState$.subscribe(state =>{
            if(state?.open){
                let qp  = getQueryStringFromParams({isIframe:1,open:state.open,goalId:state.goalId,pId:state.pId,pType:state.pType,stageOne:state.stageOne, coupon: state.couponCode});
                this.loggedOutPurchaseModalUrl = "/logged-out-purchase" + qp;
                this.loggedOutPurchaseModalIFrameURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.loggedOutPurchaseModalUrl);
                this.showLoggedOutPurchaseModal = state.open;
            }
        })
    }

    @clientOnly()
    setEmiIframeModalData(){
        this.emiIframeModalState$.subscribe(modalState => {
            if(modalState?.openModal){
                let qp = getQueryStringFromParams(modalState);
                this.emiIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/emi${qp}`);
                this.showEMIModal = modalState.openModal
                if(this.showEMIModal){
                    showLoader();
                    this.emiModalType = modalState.modalType;
                    this.platformService.addClass('modal-open');
                    this.platformService.addClass('emi-iframe-open');
                    this.platformService.hideIntercom();
                    this.platformService.hideLivePanel();
                }
            }else{
                this.platformService.removeClass('emi-iframe-open');
                this.showEMIModal = false;
                this.emiIframeUrl = null;
            }
        })
    }


  showPromptMessage(){
    if(!this.isServer) {
      let promptMessage = getLocalStorage('promptMessageMGoal') || '';
      setTimeout(()=>{
          removeLocalStorage('promptMessageMGoal');
      },1000);
      if(promptMessage && this.isLoggedIn){
        this.store.dispatch(showAlert({message:promptMessage,timer:3000}));
      }
    }
  }

    ngAfterViewInit() {
        if(!isServer()){
            this.ngZone.runOutsideAngular(()=>{
                unbindWaffleComponents();
                setTimeout(()=>{
                    checkWaffleComponent("$$all");
                    let coldboot : any = window['coldboot'];
                    let pagePath = this.platformService.getPathName();
                    if(coldboot && typeof coldboot.complete == 'function' && !allClientOnlyPaths.includes(pagePath)) {
                        coldboot.complete();
                        if(coldboot.onComplete){
                            coldboot.onComplete.finally(() => {
                                this.changeDetectionRef.markForCheck();
                            });
                        }
                    }
                },100);
            });
        }
    }

    getCurrentWindow(){
        if(isServer()){
            return;
        }
        try{
            if(window.top.location.pathname){
                return window.top;
            } else {
                return window;
            }
        } catch(e) {
            console.error('Cross Origin error:', e);
            return window;
        }
    }

    hidePopupModal() {
        this.platformService.removeClass('modal-open');
        this.changeDetectionRef.detectChanges();
    }

    checkForYtPopup() {
        let currUrl = this.platformService.getPathName();
        if(currUrl.includes('/login') || currUrl.includes('/mgoal') || currUrl.includes('test') || currUrl.includes('coaching') || currUrl.includes('/previous')) {
            return;
        }
        if(currUrl.includes('/up-police')) {
            // this.showYouTubePopup = true;
        }
    }
}
