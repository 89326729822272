import {Injectable} from "@angular/core";
import {NetworkService} from "@core/services/network.service";
import {
    createAPIParams, Enrollable, getClassObject, getDataLayerObject,
    PAYMENT_STAGES,
    PaymentParams,
    PAYMENT_CONST_OBJ,
    createEmiAPIParams,
    EmiPaymentParams
} from "@shared/utility/payment-utilities";
import {getCurrentVisitSource, setStudentData} from "@shared/utility/user";
import {
    getLocalStorage,
    getPageText, getPageType,
    hideLivePanel,
    hideLoader, isServer,
    reloadPageWithDelay, removeLocalStorage,
    setLocalStorage,
    setToSessionStorage,
    showLivePanel,
    showLoader
} from "@shared/utility/tb-common";
import {PlatformService} from "@core/services/platform-service";
import {loadScript} from "@shared/utility/dynamic-loader";
import {TbcoreService} from "@core/services/tbcore.service";
import {clientOnly} from "@shared/utility/platform-decorators";
import {studentMeApi} from "@models/entity/student/student.adapter";
import {
    getSimplFailedUrl,
    getSimplSuccessUrl,
    PG_BNPL,
    PG_SIMPL,
    PROJECT_PLUTUS,
    REDIRECT_PAYMENT_GATEWAYS,
    referrerType,
    SIMPL_TRANSACTION_LS_KEY,
    TRANSACTION_LS_KEY,
    WEBVIEW_TO_ANDROID_BRIDGE
} from "@shared/utility/constants";
import {GAEventService} from "@core/services/ga-event.service";
import {MS_IN_ONE_DAY} from "@shared/utility/date-utilities";
import {setLoggedOutPurchaseModalActivationCode, setLoggedOutTransactionState, showAlert} from "@core/application-state/app.actions";
import {Store} from "@ngrx/store";
import { StudentService } from "./student.service";
import {ActivatedRoute} from "@angular/router";


declare var Razorpay : any;
declare var window : any;


@Injectable({ providedIn: 'root' })
export class PaymentService {

    private paymentStage = PAYMENT_STAGES.NOT_STARTED;
    private enrollIn : Enrollable = {};
    private classObj : any = {};
    private passInfo : any = {};
    private DL_OBJ : any = {};
    private ORDER_ID : string;
    private isRazorPayLoaded = false;
    private redirect_url;
    private loggedOutTrans:any;
    private simpl_transaction;
    private passIndex;
    private emiData : any = {};

    @clientOnly()
    public ensureRazorPay(){
        let interval;
        return new Promise<void>((resolve,reject)=>{
           try {
               if(this.isRazorPayLoaded) { resolve(); }
               //load Razor pay
               let ref = loadScript('https://checkout.razorpay.com/v1/checkout.js');
               ref.addEventListener('load',()=>{
                    clearInterval(interval);
                    interval = setInterval(()=>{
                        if(window.Razorpay){
                            this.isRazorPayLoaded = true;
                            resolve();
                            clearInterval(interval);
                        }
                    },100);
               });
           } catch (e) {
               reject(e);
               clearInterval(interval);
           }
        });
    }

    constructor(private network: NetworkService, private platformService: PlatformService,private store:Store<any>, private coreService:TbcoreService, private gaEventService:GAEventService,private studentService:StudentService, private route: ActivatedRoute) {
        if(platformService.isLoggedIn()){
            this.ensureRazorPay();
        }
    }

    private showAlert(message?){
        let isPlutus = this.platformService.currentProjectName() === PROJECT_PLUTUS;
        let alertMsg = `Please check your internet connection or contact at support@${isPlutus ? 'plutus' : 'testbook'}.com`;
        this.store.dispatch(showAlert({message :message || alertMsg, timer: 5000}));
    }

    @clientOnly()
    public initiatePayment(paymentParams : PaymentParams,additionalOptions : any){
        if(!this.platformService.isLoggedIn() && !paymentParams.isLoggedOutTrans){ return console.error("Cannot initiate payment if user is logged out"); }
        if(this.paymentStage == PAYMENT_STAGES.STARTED) { return Promise.reject("Payment ongoing");}
        this.paymentStage = PAYMENT_STAGES.STARTED;
        this.enrollIn = paymentParams.enrollIn || {};
        this.passIndex = paymentParams?.passIndex || 0;
        this.hideDistractingUIElements();
        showLoader("Initialising Payment");
        let params = createAPIParams(paymentParams);
        let body = {
            visitSource: getCurrentVisitSource()
        };
        this.network.post('v2/payment/log',body,params).subscribe((response : any)=>{
            if (!REDIRECT_PAYMENT_GATEWAYS.includes(paymentParams.paymentGateway)) {
                hideLoader();
            }
            if(!response.success) {
                this.showAlert(response.message);
                this.paymentStage = PAYMENT_STAGES.FAILED;
            }
            let subtotal = response.data.razorpayClientParams && (response.data.razorpayClientParams.amount / 100);
            if (!REDIRECT_PAYMENT_GATEWAYS.includes(paymentParams.paymentGateway)) {
                var RAZORPAY_OBJ: any = {
                    pid: paymentParams.products,
                    data: response.data || {},
                    coupon: paymentParams.coupon || '',
                    discount: paymentParams?.oldCost - subtotal,
                    passIndex: paymentParams?.passIndex,
                    subtotal: subtotal,
                    customerId: (response.data.razorpayClientParams && response.data.razorpayClientParams.customerId),
                    ...additionalOptions
                };
                if (RAZORPAY_OBJ.hasOwnProperty('passInfo')) {
                    this.passInfo = RAZORPAY_OBJ.passInfo;
                }
                if(paymentParams.isLoggedOutTrans){
                    RAZORPAY_OBJ['offlineUser'] = {
                        isLoggedOutTrans : paymentParams.isLoggedOutTrans,
                        name : paymentParams.name,
                        mobile : paymentParams.mobile
                    }
                }
                if (RAZORPAY_OBJ.data.hasOwnProperty("url")) {
                    // for 0 rs transaction we need to reload the page
                    this.platformService.setCookie('userAccess', '', 0);
                    setTimeout(() => {
                        try{
                            window.location.top.href = window.location.top.pathname;
                        } catch (e) {
                            window.location.href = window.location.pathname;
                        }
                    }, 1000);
                } else {
                    if (RAZORPAY_OBJ.hasOwnProperty('classObj')) {
                        this.classObj = getClassObject(RAZORPAY_OBJ.classObj);
                    }
                    if (RAZORPAY_OBJ.hasOwnProperty('redirect_url')) {
                        this.redirect_url = RAZORPAY_OBJ.redirect_url;
                    }

                    this.DL_OBJ = getDataLayerObject(RAZORPAY_OBJ);
                    setToSessionStorage('DL_OBG', this.DL_OBJ);
                    this.openRazorpayPrompt(RAZORPAY_OBJ);
                }
            } else {
                if(paymentParams.paymentGateway === PG_SIMPL) {
                    if (response.data.paymentLink) {
                        window.location.href = response.data.paymentLink;
                    } else {
                        window.location.href = getSimplFailedUrl('PAYMENT_LINK_ERROR');
                    }
                }
                else if(paymentParams.paymentGateway === PG_BNPL) {
                    if(response.data.linkingUrl){
                        window.location.href = response.data.linkingUrl;
                    } else if(response.data?.transaction) {
                        window.location.href = getSimplSuccessUrl(response.data.transaction);
                    } else {
                        window.location.href = getSimplFailedUrl('BNPL_ELIGIBILITY_ERROR');
                    }
                } else {
                    window.location.href = getSimplFailedUrl('INVALID_PG');
                }
            }
        },(error)=>{
            if (REDIRECT_PAYMENT_GATEWAYS.includes(paymentParams.paymentGateway)){
                console.log(error?.error?.message);
                window.location.href = `/initiate-payment/continue?status=FAILED&error_code=${error?.error?.message}`;
            }
            hideLoader();
            this.paymentStage = PAYMENT_STAGES.FAILED;
            this.showAlert(error?.error?.message);
        });
    }

    public emiInitiatePayment(emiPaymentParams : EmiPaymentParams,additionalOptions : any){
        showLoader("Initializing Payment");
        this.emiData = {
            ...this.emiData, 
            isEmiFlow: true
        };
        let emiParams = createEmiAPIParams(emiPaymentParams);
        this.network.post(PAYMENT_CONST_OBJ.createEmi, emiParams, {}).subscribe((response : any) => {
            if(response && response.success && response.data && response.data?.duePayments?.length){
                this.emiData = {...this.emiData, ...response.data};
                let duePayments = this.emiData.duePayments;
                emiPaymentParams.paymentsEmi = duePayments.join(",");
                emiPaymentParams.isEmiFlow = true;
                this.initiatePayment(emiPaymentParams,additionalOptions);
            }else{
                hideLoader();
                this.showAlert(response?.message || 'Something Went Wrong!!!');
                return;
            }
        }, (error:any)=>{
            hideLoader();
            this.showAlert(error?.error?.message || 'Something Went Wrong!!!');
            return;
        });
    }

    public redirectToApp(response){
        if (!isServer() && window[WEBVIEW_TO_ANDROID_BRIDGE]){
            hideLoader();
            window[WEBVIEW_TO_ANDROID_BRIDGE].onPaymentHandled(JSON.stringify(response));
        }
    }

    @clientOnly()
    public redirectToWeb(redirectResponse, simpl_transaction) {
        showLoader('Loading');
        let redirectUrl;
        if(!redirectResponse.success){
            if(redirectResponse.error_code !== 'USER_CANCELED'){
                let statusAlert = {
                    success: false,
                    message: 'Something went wrong'
                }
                setLocalStorage(SIMPL_TRANSACTION_LS_KEY, JSON.stringify(statusAlert), 1);
            }
        }else{
            let txnObj = {
                passInfo: {
                    passType: 'goalSubs',
                    goal: simpl_transaction?.goal
                }
            };
            this.platformService.setCookie('tb_txnid', this.generateTranxObj(txnObj), 1);
            localStorage.removeItem(SIMPL_TRANSACTION_LS_KEY);
        }
        redirectUrl = simpl_transaction?.redirectSlug || '/';
        this.platformService.redirect(redirectUrl);
    }

    generateTranxObj(txnObj){
        let sanitizedTxnObj = {...txnObj};
        sanitizedTxnObj.passInfo = {...sanitizedTxnObj?.passInfo};
        if(sanitizedTxnObj?.passInfo?.pass){
            let pass = sanitizedTxnObj?.passInfo?.pass;
            sanitizedTxnObj.passInfo.pass = {
                id: pass?.id,
                title: pass?.title,
                type: pass?.type,
                emiPlanPrice: pass?.emiPlanPrice,
                payableAmount: pass?.payableAmount
            };
        }
        if(sanitizedTxnObj?.passInfo?.goal){
            let goal = sanitizedTxnObj?.passInfo?.goal;
            sanitizedTxnObj.passInfo.goal = {id:goal?.id,slug:goal?.slug || goal?.properties?.slug,title: goal?.title || goal?.properties?.title};
        }
        return  JSON.stringify(sanitizedTxnObj);
    }


    public async openRazorpayPrompt(razorPayParams){
        let clientParams = razorPayParams.data.razorpayClientParams || {};
        this.ORDER_ID = clientParams.order_id || "";
        var options = {
            "key": clientParams.key || "",
            "amount": clientParams.amount || 0, // 2000 paise = INR 20
            "name": clientParams.name || "",
            "description": clientParams.description || "",
            "handler": (obj) => this.razorPayCheckoutFormHandler(obj || {}), //payment verification
            "order_id": clientParams.order_id || "",
            "theme": {
                "color": "#0ad0f4"
            },
            "modal": {
                ondismiss:  () => {
                    // payment cancelled (when user closes the form while transaction is still in progress)
                    this.paymentStage = PAYMENT_STAGES.NOT_STARTED;
                    this.showDistractingUIElements();
                    try{
                        window.top.postMessage({source:'pricingModalClosing',closingPricingModal:true}, '*');
                    } catch (e) {
                        console.log(e);
                    }
                },
                escape: false
            }
        };
        this.paymentStage = PAYMENT_STAGES.WAITING;
        if(razorPayParams.offlineUser){
            options["prefill"] = {
                "name": razorPayParams.offlineUser.name,
                "email": 'no.user.email@testbook.com',
                "contact": razorPayParams.offlineUser.mobile
            }
        }else{
            let student = await this.studentService.loadStudent();
            options["prefill"] = {
                "name":  student.name,
                "email": student.email || 'no.user.email@testbook.com',
                "contact":  student.mobile.slice(2)
            }
        }

        await this.ensureRazorPay(); // typically this should be loaded already but just incase.
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed',function(response){

            this.paymentStage = PAYMENT_STAGES.FAILED;
            var txnObj : any = {
                classObj: this.classObj,
                data: razorPayParams.data,
                dlObj: this.DL_OBJ,
                pathName: window.location && window.location.pathname || ''
            };

            if (txnObj.classObj && txnObj.classObj.isClassCase) {
                txnObj.productCategory = 'SelectCourse';
            } else {
                txnObj.productCategory = 'GlobalPass';
            }
            var failureInfo : any ={};
            failureInfo.transID=txnObj.data.transaction;
            failureInfo.partner=this.platformService.getCookie("tb_partner") || "others";
            failureInfo.productID=txnObj.dlObj.pid;
            failureInfo.productName=txnObj.dlObj.name;
            failureInfo.productCostArray=txnObj.dlObj.cost;
            failureInfo.page=getPageText();
            failureInfo.pagePath=txnObj.pathName;
            failureInfo.productCategory=txnObj.productCategory;
            failureInfo.couponCode = txnObj.dlObj.coupon || '';
            failureInfo.subTotal = txnObj.dlObj.subTotal;
            failureInfo.finalAmount = txnObj.dlObj.cost;

            var utmObj=getCurrentVisitSource();
            failureInfo.transUtmSource=utmObj.utm_source || "";
            failureInfo.transUtmMedium=utmObj.utm_medium || "";
            failureInfo.transUtmCampaign=utmObj.utm_campaign || "";
            failureInfo.isEcard=false;
            if (txnObj.dlObj && txnObj.dlObj.hasOwnProperty('productType') && txnObj.dlObj.productType === 'ComboPack') {
                failureInfo.productCategory = txnObj.dlObj.productType;
            }

            if (txnObj.dlObj.passType) {
                var quantity=Number(txnObj.dlObj.quantity) || 1;
                failureInfo.productCategory = txnObj.dlObj.passType === 'LearningPass' ? (quantity > 1 ? 'CoursePassGroupPurchase' : 'CoursePass') : failureInfo.productCategory
            }
            this.gaEventService.sendTransactionFailedEvent(failureInfo);
        })
        rzp1.open();
    }

    private razorPayCheckoutFormHandler(params){
        showLoader('Payment in progress, Please do not refresh or close');
        this.network.post('v2/payment/razorpay', {},params).subscribe((response : any)=>{
            hideLoader();
            if (response && response.data) {
                this.paymentStage = PAYMENT_STAGES.FINISHED;
                if(response.data.status == "loggedoutPaid"){
                    this.loggedOutTrans = response.data;
                }
                if(response.data.status == "emandatePending"){
                    //down payment done for emi
                    this.emiData = {
                        ...this.emiData, 
                        isDownPaymentDone:true
                    };
                }
                this.postTransactionHandler(response.data.transaction || "");
            } else {
                this.paymentStage = PAYMENT_STAGES.FAILED;
                this.showAlert(response && response.message);
            }
        },(response : any)=>{
            this.paymentStage = PAYMENT_STAGES.FAILED;
            hideLoader();
            this.showAlert();
        })
    }
    private registerClass(classId){
        return new Promise<void>((resolve,reject)=>{
            this.network.post(`v2.2/courses/${classId}/activity`,{},{isEnrollRequest: true}).subscribe((response:any)=>{
                resolve();
            }, (response : any) =>{
                reject();
                this.showAlert(response.message);
            });
        });
    }
    private async postTransactionHandler(transaction){
        if(this.enrollIn){
            if(this.enrollIn.classId) {
               await this.registerClass(this.enrollIn.classId);
            }
        }
        this.postSuccessfulTransactionHandler(transaction);
    }
    @clientOnly()
    private postSuccessfulTransactionHandler(transaction){
        this.platformService.setCookie('tb_refresh', true); // to refresh php session
        this.platformService.setCookie('userAccess', '', 0);
        if(this.platformService.isLoggedIn()){
            studentMeApi.apiCall(this.network, {}).toPromise()
                .then((studentData: any) => {
                    setStudentData(studentData.data);
                }).catch((error) => {
                console.log("error getting student",error);
            });
        }
        var transactionObj : any = {
            classObj: this.classObj,
            txnId: transaction,
            dlObj: this.DL_OBJ,
            passInfo: this.passInfo,
            pathName: window.parent.location && window.parent.location.pathname || '',
            isLoggedOutTrans:!this.platformService.isLoggedIn(),
        };
        if (window["stuPassesData"] && window["stuPassesData"].passes && window["stuPassesData"].passes.length > 0) {
            transactionObj.purchaseType = 'Renew';
            if (window["stuPassesData"].globalPassExpiry._id !== '') {
                transactionObj.hoursLeftBeforePurchase = Math.round((new Date(window["stuPassesData"].globalPassExpiry.expiry).getTime() - new Date(window["stuPassesData"].curTime).getTime()) / 3600000);
            }
        } else {
            transactionObj.purchaseType = 'Direct';
        }
        if(this.emiData?.isEmiFlow && this.emiData?.isDownPaymentDone){
            transactionObj.emiData = {
                isEmiFlow: true,
                isDownPaymentDone: true
            }
        }
        transactionObj.dlPage = getPageText();
        this.pushDetailsToDataLayerSCB(transactionObj);
        this.platformService.setCookie('tb_txnid', this.generateTranxObj(transactionObj), 1);
        if(!this.platformService.isLoggedIn()){
            this.platformService.setCookie('trans_code',this.loggedOutTrans?.code,7);
        }
        showLoader();
        if(!this.platformService.isLoggedIn()){
            this.store.dispatch(setLoggedOutPurchaseModalActivationCode({code:this.loggedOutTrans?.code}));
            this.store.dispatch(setLoggedOutTransactionState({status:true}));
            hideLoader();
            return;
        }
        if((this.classObj && this.classObj.classUrl) || this.redirect_url){
            let url = this.redirect_url || this.classObj.classUrl;
            setTimeout(() => {
                let forceTopWindowRedirect = window.self !== window.top;
                this.platformService.redirect(url, 302, forceTopWindowRedirect);
            }, 1000);
        } else {
            reloadPageWithDelay(true);
        }
    }

    pushDetailsToDataLayerSCB(txnObj) {
        if (txnObj.classObj && txnObj.classObj.isClassCase) {
            txnObj.productCategory = txnObj.dlObj.type || 'LearnCourse';
            txnObj.expiry = txnObj.classObj.classTill;
            txnObj.stopEvents = txnObj.classObj.stopEvents;
        } else {
            
            var date = new Date();
            if(txnObj.classObj && txnObj.classObj.isLearningPass){
                txnObj.productCategory = 'CoursePass';
            } else {
                switch (txnObj.passInfo.passType) {
                    case 'goalSubs':
                        txnObj.productCategory = 'SuperCoaching';
                        break;
                    case 'globalPass':
                        txnObj.productCategory = 'GlobalPass';
                        break;
                    case 'passPro':
                        txnObj.productCategory = 'PassPro';
                        break;
                }
            }
            txnObj.expiry = new Date(date.setTime(date.getTime() + txnObj.dlObj.validity * MS_IN_ONE_DAY)).toISOString();
            txnObj.stopEvents = txnObj.dlObj.stopEvents;
        }
        let transactionInfo : any = {};
        let referrerPageType = this.route.snapshot.queryParams[referrerType];
        transactionInfo.transID = txnObj.txnId;
        transactionInfo.productID = txnObj.dlObj.pid;
        transactionInfo.productName = txnObj.dlObj.name;
        transactionInfo.finalAmount = txnObj.dlObj.cost;
        transactionInfo.productDuration = txnObj.dlObj.validity;
        transactionInfo.page = txnObj.dlPage || getPageText();
        transactionInfo.pagePath = txnObj.pathName;
        transactionInfo.pageType = getPageType();
        transactionInfo.referrerType = referrerPageType || getPageType();
        transactionInfo.purchaseType = txnObj.purchaseType;
        transactionInfo.productCategory = txnObj.productCategory;
        transactionInfo.productType = txnObj.productCategory;
        transactionInfo.couponCode = txnObj.dlObj.coupon || '';
        transactionInfo.subTotal = txnObj.dlObj.subTotal;
        transactionInfo.stopEvents = txnObj.stopEvents || false;
        transactionInfo.productExpiry = txnObj.expiry;
        transactionInfo.module = txnObj.dlObj.module;
        transactionInfo.quantity = Number(txnObj.dlObj.quantity) || 1;

        if (txnObj.dlObj && txnObj.dlObj.hasOwnProperty('productType') && txnObj.dlObj.productType === 'ComboPack') {
            transactionInfo.productCategory = txnObj.dlObj.productType;
        }

        if (txnObj.dlObj.passType) {
            transactionInfo.productCategory = txnObj.dlObj.passType === 'LearningPass' ? (transactionInfo.quantity > 1 ? 'CoursePassGroupPurchase' : 'CoursePass') : transactionInfo.productCategory
        }
        this.gaEventService.sendTransactedEvent(transactionInfo);

        let purchasedInfo : any = {};
        purchasedInfo.transaction_id = transactionInfo.transID;
        purchasedInfo.productID = transactionInfo.productID;
        purchasedInfo.productName = transactionInfo.productName;
        purchasedInfo.value = transactionInfo.finalAmount;
        purchasedInfo.currency = 'INR';
        purchasedInfo.coupon = transactionInfo.couponCode;
        purchasedInfo.discount = txnObj.dlObj.discount || 0;

        purchasedInfo.items = [{
            item_id: transactionInfo.productID,
            item_name: transactionInfo.productName,
            coupon: transactionInfo.couponCode,
            discount: 0,
            index: txnObj.dlObj.passIndex || 0,
            price: transactionInfo.finalAmount,
            quantity: transactionInfo.quantity,
        }];

        this.gaEventService.sendPurchasedEvent(purchasedInfo);
    }
    public hideDistractingUIElements(){
        this.platformService.hideIntercom();
        hideLivePanel();
    }

    
    public showDistractingUIElements(){
        this.platformService.showIntercom();
        showLivePanel();
    }
}